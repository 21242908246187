import { createSelector } from 'reselect';

const userSelect = (state) => state.user;

export const reselectUser = createSelector(
  userSelect,
  ({ isLoading, isAuthenticated, availableAuthentications,sessionId, accessToken,refreshToken  }) => ({
    isLoading,
    isAuthenticated,
    availableAuthentications,
    sessionId, 
    accessToken, 
    refreshToken 
  })
);
