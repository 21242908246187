import { jsonRequest } from '../helpers/api.request.helper';
import { baseUrl } from '../helpers/baseUrl';

export const initRegistration = ({ idNumber, personalNumber, mobilePhone,citizenshipCountryCode, isDualCitizen}) =>
  jsonRequest(`${baseUrl}/v1/business/init-registration`, true, { idNumber, personalNumber, mobilePhone,citizenshipCountryCode,isDualCitizen }, 'POST');

  export const startRefreshTokenFetch = (payload) =>
  jsonRequest(`${baseUrl}/v1/business/refresh-token`, true, payload, 'POST');

  export const fetchChangeMobile = (mobilePhone) =>
  jsonRequest(`${baseUrl}/v1/business/change-mobile`, true, {mobilePhone}, 'POST');

/**
 *
 * verifications
 */

// remote

export const startRemoteVerification = () =>
  jsonRequest(`${baseUrl}/v1/business/start-remote-verification`, true, {}, 'POST');

export const finishRemoteVerification = (sessionId) =>
  jsonRequest(`${baseUrl}/v1/business/finish-remote-verification`, true, {sessionId:sessionId}, 'POST');

// omnichanel
export const startOmnichanelVerification = () =>
  jsonRequest(`${baseUrl}/v1/business/start-omnichannel-verification`, true, {}, 'POST');

export const finishOmnichanelVerification = (sessionId,headers) =>
  jsonRequest(`${baseUrl}/v1/business/finish-omnichannel-verification`, true, {sessionId}, 'POST', headers);
// Email verification
export const startEmailVerification = () =>
  jsonRequest(`${baseUrl}/v1/business/choose-email-verification`, true, {}, 'POST');

/**
 * AML
 *  */

export const fetchCustomerAml = () =>
  jsonRequest(`${baseUrl}/v1/business/check-aml-status`, true);
export const fetchOrganizationAml = () =>
  jsonRequest(`${baseUrl}/v1/business/check-organization-aml-status`, true);

export const fetchCurrentStep = () => jsonRequest(`${baseUrl}/v1/business/application`, true);

export const fetchCancelApplication = (optionalHeaders) =>{
  return jsonRequest(`${baseUrl}/v1/business/cancel-application`, true, {}, 'POST',optionalHeaders);
} 

/**
 * contact steps
 */

export const fetchUpdateContact = ({
  physicalAddress,
  companyEmail,
  representatorEmail,
  companyPhone,
  companyWebsite,
  companyNameEng,
}) =>
  jsonRequest(
    `${baseUrl}/v1/business/update-kyc-contact`,
    true,
    { physicalAddress, companyEmail, representatorEmail, companyPhone, companyWebsite, companyNameEng },
    'POST'
  );

/**
 * activities step
 */

export const fetchActivities = (legalFormId) => jsonRequest(`${baseUrl}/v1/business/activities/${legalFormId}`, false);

export const fetchAdditionalActivities = () => jsonRequest(`${baseUrl}/v1/business/additional-activities`, false);

export const fetchCountries = () => jsonRequest(`${baseUrl}/v1/business/countries`, false);

export const fetchEmployees = () => jsonRequest(`${baseUrl}/v1/business/employ-counts`, false);

export const fetchAnnualTurnovers = () => jsonRequest(`${baseUrl}/v1/business/annual-turnovers`, false);

export const fetchClientTypes = () => jsonRequest(`${baseUrl}/v1/business/client-types`, false);

export const fetchRegistrationReasons = () => jsonRequest(`${baseUrl}/v1/business/registration-reasons`, false);

export const fetchBanks = () => jsonRequest(`${baseUrl}/v1/business/banks`, false);

export const fetchUpdateActivity = ({
  activityId,
  clientTypeId,
  additionalActivityId,
  countryId,
  employcountId,
  annualTurnoverId,
  expectedannualTurnoverId,
  additionalReason,
  anotherBankName,
  clientRegistrationReasonId,
  anotherBankCodes,
}) =>
  jsonRequest(
    `${baseUrl}/v1/business/update-kyc-activity`,
    true,
    {
      activityId,
      clientTypeId,
      additionalActivityId,
      countryId,
      employcountId,
      annualTurnoverId,
      expectedannualTurnoverId,
      clientRegistrationReasonId,
      additionalReason,
      anotherBankName,
      anotherBankCodes,
    },
    'POST'
  );

/**
 * services step
 */
export const fetchCurrencies = () => jsonRequest(`${baseUrl}/v1/business/currencies`, false);

export const fetchServicesCenters = () => jsonRequest(`${baseUrl}/v1/business/branches`, false);

export const fetchBusinessCardBase64 = () => jsonRequest(`${baseUrl}/v1/business/cardbase64`, false);

export const fetchUpdateService = ({
  accountCurrencies,
  createCard,
  CompanyNameOnCard,
  cardCurrencies,
  receiveEcommerce,
  brandName,
  ecommerceWebsite,
  receivePosterminal,
  receiveCashBack,
  serviceCenterid,
  activateSmsBank,
  smsbankLanguage,
}) =>
  jsonRequest(
    `${baseUrl}/v1/business/update-kyc-services`,
    true,
    {
      accountCurrencies,
      createCard,
      CompanyNameOnCard,
      cardCurrencies,
      receiveEcommerce,
      brandName,
      ecommerceWebsite,
      receivePosterminal,
      receiveCashBack,
      serviceCenterid,
      activateSmsBank,
      smsbankLanguage,
    },
    'POST'
  );

/**
 * summary step
 */

export const fetchSendSmsCode = () => jsonRequest(`${baseUrl}/v1/business/send-code`, true, {}, 'POST');
export const fetchConfirmApplication = ({ smsCode }) =>
  jsonRequest(`${baseUrl}/v1/business/confirm`, true, { smsCode }, 'POST');
