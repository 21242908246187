import React from 'react';

const renderSwitch = (size) => {
  switch (size) {
    case 'xxs':
      return 'loader-xxs';
    case 'xs':
      return 'loader-xs';

    case 'xsmall':
      return 'loader-xsm';

    case 'small':
      return 'loader-sm';

    case 'large':
      return 'loader-lg';

    default:
      return '';
  }
};

export const LbLoader = ({ isLoading, inCenter, size, className }) => {
  return isLoading ? (
    <div className={`loader-out${inCenter ? ' full-size ' : ''} ${className ? className : ''} ${renderSwitch(size)}`}>
      <div className={`loader ${renderSwitch(size)}`}></div>
    </div>
  ) : null;
};
