import React from "react";
import Favicons from "./components/common/simple/Favicon";
import { withTranslation } from "react-i18next";
import { Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import { SvgIcons } from "./components/common/simple/SvgIcons";
import { ScrollTop } from "./components/common/simple/ScrollTop";
import { PrivateRoute } from "./components/common/simple/PrivateRoute";
import { LbToastContainer } from "./components/common/simple/LbToastContainer";
import { LbLoader } from "./components/common/simple/LbLoader";
import {
  ManageToken,
  SessionConfiguration,
} from "./components/common/simple/SessionConfiguration";

const MainContainer = loadable(() => import("./module/Main/MainContainer"), {
  fallback: <LbLoader isLoading inCenter />,
});
const InitContainer = loadable(
  () => import("./module/signup/initial/InitialContainer"),
  {
    fallback: <LbLoader isLoading inCenter />,
  }
);
const ChooseTypeContainer = loadable(
  () => import("./module/signup/type/ChooseTypeContainer"),
  {
    fallback: <LbLoader isLoading inCenter />,
  }
);
const ContactContainer = loadable(
  () => import("./module/signup/contact/ContactContainer"),
  {
    fallback: <LbLoader isLoading inCenter />,
  }
);
const RegisterNotificationContainer = loadable(
  () => import("./module/signup/notification/NotificationContainer"),
  {
    fallback: <LbLoader isLoading inCenter />,
  }
);

const VerificationCallBackContainer = loadable(
  () => import("./module/signup/verification/VerificationCallBackContainer"),
  {
    fallback: <LbLoader isLoading inCenter />,
  }
);
const LbCallBackContainer = loadable(
  () => import("./module/signup/verification/LbCallBackContainer"),
  {
    fallback: <LbLoader isLoading inCenter />,
  }
);

function App() {
  return (
    <ScrollTop>
      <SvgIcons />
      <Favicons />
      <LbToastContainer />
      <ManageToken />
      <Switch>
        <Route exact path="/" component={MainContainer} />
        <Route
          exact
          path="/verificationcallback"
          component={VerificationCallBackContainer}
        />
        <Route exact path="/auth/callback" component={LbCallBackContainer} />
        <Route
          path="/register"
          render={() => {
            return (
              <>
                {/* <Switch>
                  <Route exact path='/register/finish' component={RegisterNotificationContainer} />
                </Switch>
                <SessionConfiguration>
                  <Switch>
                    <Route exact path='/register/init' component={InitContainer} />
                    <PrivateRoute exact path='/register/type' component={ChooseTypeContainer} />
                    <PrivateRoute path='/register' component={ContactContainer} />
                  </Switch>
                </SessionConfiguration> */}
                <SessionConfiguration>
                  <Switch>
                    <Route
                      exact
                      path="/register/init"
                      component={InitContainer}
                    />
                    <PrivateRoute
                      exact
                      path="/register/type"
                      component={ChooseTypeContainer}
                    />
                    <Route
                      exact
                      path="/register/finish"
                      component={RegisterNotificationContainer}
                    />
                    <PrivateRoute
                      path="/register"
                      component={ContactContainer}
                    />
                  </Switch>
                </SessionConfiguration>
              </>
            );
          }}
        />
      </Switch>
    </ScrollTop>
  );
}

export default withTranslation()(App);
