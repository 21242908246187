import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchCurrentStep } from '../../../api/onBoarding.api';
import { reselectUser } from '../../../package/store/reselect/user.reselect';
import { AVAILABLE_STEPS, history } from '../../../helpers/history';
import { startFetchRefreshToken } from '../../../package/store/actions/user.actions';

export const ManageToken = ()=>{
  const dispatch = useDispatch()
  const timerRef = useRef(null)
  // window.timerRef = timerRef
  const { accessToken, refreshToken } = useSelector(reselectUser);
  useEffect(()=>{
    // console.log("timerRef",1)
    if(timerRef.current) return
// console.log("timerRef",2)
    if(accessToken){
      // console.log("timerRef",3)
        timerRef.current = setInterval(async()=>{
          // console.log("timerRef",4,accessToken,"refresh",refreshToken)
          dispatch(startFetchRefreshToken())
        },240000 )
        //240000
    }else{
      // console.log("timerRef",6)
      clearInterval(timerRef.current)
    }

    return ()=>{
      // console.log("timerRef",5)
      // clearInterval(timerRef.current)
    }
  },[dispatch,accessToken,refreshToken])
  return null
}

export const SessionConfiguration = ({ children }) => {
  
  const { pathname, state } = useLocation();
  const { accessToken} = useSelector(reselectUser);


  

  /**
   * methods
   */

  const handleCheckCurrentStep = useCallback(async () => {
    
    if (
      !pathname ||
      // !accessToken ||
      !pathname.includes('/register') ||
      pathname !== '/register/finish' ||
      (state && state.needCheckSession === false)
    ) {
      
      /**
       * before update state
       */
      let { state = {} } = history.location;
      if (state && 'needCheckSession' in state) delete state['needCheckSession'];

      history.replace({
        pathname,
        state: { ...state },
      });
      return;
    }

    try {
      if(!accessToken) {
        history.push({
            pathname: "/",
            state: {
              needCheckSession: false,
            },
          });
      }
      let data = await fetchCurrentStep(accessToken);
      if (data) {
        let { currentStep } = data;
        currentStep = AVAILABLE_STEPS[currentStep];
        // TODO: must add new url paths
        // if (currentStep && !pathname.includes(`${currentStep}`) && state && state.needCheckSession !== false) {
        if (currentStep && !pathname.includes(`${currentStep}`)) {
          history.push({
            pathname: currentStep,
            state: {
              needCheckSession: false,
            },
          });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [pathname,accessToken]);

  useEffect(() => {
    /**
     * checking on refresh page
     */
    handleCheckCurrentStep();
  }, [handleCheckCurrentStep]);

  return <>{children}</>;
};
