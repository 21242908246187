import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();
// export const history = createBrowserHistory({forceRefresh:true})
history.listen((location, action) => {
  if (action === 'POP') {
    history.replace(location.pathname, {});
  }
});

/**
 * back available next steps
 */

export const AVAILABLE_STEPS = {
  InitRequest: '/register/init',
  ChooseIdentificationType: '/register/type',
  KYCContact: '/register/contact',
  KYCActivity: '/register/activity',
  KYCServices: '/register/services',
  Summary: '/register/summary',
};
