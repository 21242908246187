import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { reselectUser } from '../../../package/store/reselect/user.reselect';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, accessToken } = useSelector(reselectUser);

  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        isAuthenticated && accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
