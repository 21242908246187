import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import helperReducer from './helper.reducer';
import applicationReducer from './application.reducer';

export default combineReducers({
  user: userReducer,
  helpers: helperReducer,
  application: applicationReducer,
});
