import { START_FETCH_PHONE_PREFIXES, SET_PHONE_PREFIXES_LOADER, SET_PHONE_PREFIXES } from '../const/helper.const';

export const startFetchPhonePrefixes = () => ({
  type: START_FETCH_PHONE_PREFIXES,
});
export const setPhonePrefixesLoader = (payload) => ({
  type: SET_PHONE_PREFIXES_LOADER,
  payload,
});

export const setPhonePrefixes = (payload) => ({
  type: SET_PHONE_PREFIXES,
  payload,
});
