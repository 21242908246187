import { SET_PHONE_PREFIXES, SET_PHONE_PREFIXES_LOADER } from '../const/helper.const';

const initialState = {
  isLoadingPhonePrefixes: true,
  phonePrefixes: [],
};
export default function helperReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PHONE_PREFIXES_LOADER: {
      state = {
        ...state,
        isLoadingPhonePrefixes: action.payload,
      };
      break;
    }
    case SET_PHONE_PREFIXES: {
      state = {
        ...state,
        isLoadingPhonePrefixes: false,
        phonePrefixes: action.payload,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
