import { takeEvery, call, all, put , select} from 'redux-saga/effects';
import {
  setRefreshToken,
  setUserAvailableAuthentications,
  setUserCredentials,
  startClearUserCredentials,
} from '../actions/user.actions';
import {
  START_SET_USER_CREDENTIALS,
  START_CLEAR_USER_CREDENTIALS,
  START_CANCEL_USER_APPLICATION,
  START_FETCH_REFRESH_TOKEN,
} from '../const/user.const';
import { history } from '../../../helpers/history';

// actions

//api
import { fetchCancelApplication, startRefreshTokenFetch } from '../../../api/onBoarding.api';
import { resetApplicationState } from '../actions/application.action';
/**
 * Save user credentials
 * @param {*} actions
 */
function* startSetUserCredentialsAsync(actions) {
  try {
    const {
      info: { processTypes: availableAuthentications, sessionId, accessToken, refreshToken },
    } = actions.payload;
    if(accessToken){
      yield localStorage.setItem('lb_on_boarding_token', accessToken);
      yield localStorage.setItem('lb_on_boarding_token_exp', Date.now()+ 270000);
      yield put(setUserCredentials({ sessionId, accessToken , refreshToken , isAuthenticated: true }));
    }
    
    
    yield put(setUserAvailableAuthentications({ availableAuthentications }));
    // yield put(setTest(data));
  } catch (error) {
    // console.log("error",error)
    // yield put(tesTestLoader(false));
  }
}

function* onStartSetUserCredentials() {
  yield takeEvery(START_SET_USER_CREDENTIALS, startSetUserCredentialsAsync);
}
/**
 * Clear user credentials
 * @param {*} actions
 */
function* startClearUserCredentialsAsync(actions) {
  try {
    let date = new Date()
    yield localStorage.removeItem('lb_on_boarding_token');
    yield localStorage.removeItem('lb_on_boarding_token_exp');
    yield put(setUserCredentials({ token: null, isAuthenticated: false }));
    yield put(setUserAvailableAuthentications({ availableAuthentications: [] }));
    yield put(resetApplicationState());

    // yield put(setTest(data));
  } catch (error) {
    // yield put(tesTestLoader(false));
  }
}

function* onStartClearUserCredentials() {
  yield takeEvery(START_CLEAR_USER_CREDENTIALS, startClearUserCredentialsAsync);
}
/**
 * Clear user credentials
 * @param {*} actions
 */
function* startCancelUserApplicationAsync(actions) {
  try {
    yield fetchCancelApplication();
    yield put(startClearUserCredentials());
    yield put(resetApplicationState());

    // yield history.push({ pathname: '/' });
  } catch (error) {
    // yield put(tesTestLoader(false));
  }
}

function* onStartCancelUserApplication() {
  yield takeEvery(START_CANCEL_USER_APPLICATION, startCancelUserApplicationAsync);
}
/**
 * Clear user credentials
 * @param {*} actions
 */
function* startFetchRefreshTokenAsync(actions) {
  try {
    let {accessToken, refreshToken} = yield select(state=>state.user)
    let payload= {refreshToken,accessToken}
    if(accessToken) payload.accessToken = accessToken
    let response = yield startRefreshTokenFetch(payload);
    yield localStorage.setItem('lb_on_boarding_token', response.accessToken);
    yield localStorage.setItem('lb_on_boarding_token_exp', Date.now()+ 270000);
    yield put(setRefreshToken(response));

    // yield history.push({ pathname: '/' });
  } catch (error) {
    // yield put(tesTestLoader(false));
  }
}

function* onStartFetchRefreshToken() {
  yield takeEvery(START_FETCH_REFRESH_TOKEN, startFetchRefreshTokenAsync);
}

export default function* userSaga() {
  yield all([call(onStartSetUserCredentials), call(onStartClearUserCredentials), call(onStartCancelUserApplication),call(onStartFetchRefreshToken)]);
}
