import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const LbToastContainer = () => {
  return (
    <ToastContainer
      position='bottom-right'
      hideProgressBar={false}
      autoClose={5000}
      newestOnTop
      pauseOnFocusLoss={false}
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable={false}
      pauseOnHover={false}
      closeButton={false}
    />
  );
};
