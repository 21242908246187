import {
  SET_KYC_ACTIVITY,
  SET_KYC_CONTACT,
  SET_KYC_SERVICES,
  SET_STATE_TO_INITIAL,
  SET_REGISTER_INITIAL,
  SET_APPLICATION,
} from "../const/application.const";

export const setApplicationInitial = (payload) => ({
  type: SET_REGISTER_INITIAL,
  payload,
});

export const resetApplicationState = () => ({
  type: SET_STATE_TO_INITIAL,
});

export const setApplicationKycContact = (payload) => ({
  type: SET_KYC_CONTACT,
  payload,
});

export const setApplicationKycActivity = (payload) => ({
  type: SET_KYC_ACTIVITY,
  payload,
});
export const setApplicationKycServices = (payload) => ({
  type: SET_KYC_SERVICES,
  payload,
});

export const setApplication = (payload) => ({
  type: SET_APPLICATION,
  payload,
});
