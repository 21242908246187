/**
 * For clearing state and set default state
 */
export const SET_STATE_TO_INITIAL = "SET_STATE_TO_INITIAL";

export const SET_REGISTER_INITIAL = "SET_REGISTER_INITIAL";
export const SET_KYC_CONTACT = "SET_KYC_CONTACT";
export const SET_KYC_ACTIVITY = "SET_KYC_ACTIVITY";
export const SET_KYC_SERVICES = "SET_KYC_SERVICES";
export const SET_APPLICATION = "SET_APPLICATION";
