import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import TagManager from "react-gtm-module";
import { history } from "./helpers/history";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ConfigStore from "./package/store";
import i18n, { setI18next } from "./i18n";
import App from "./App";

import { setBaseUrls } from "./helpers/baseUrl";
import { I18nextProvider } from "react-i18next";

import "./assets/sass/site.scss";
import { setUserManagerAuthorization } from "./helpers/oidc.manager";
import { LbLoader } from "./components/common/simple/LbLoader";

let xhrConfig;

function setConsole(server) {
  if (server === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
}

const activateGoogleTagManager = (server, gtmId) => {
  if (server === "production") {
    TagManager.initialize({
      gtmId: gtmId,
    });
  }
};

const fetchConfig = () =>
  new Promise((resolve, reject) => {
    xhrConfig = new XMLHttpRequest();
    xhrConfig.open("GET", "/config.json", true);
    xhrConfig.setRequestHeader("Cache-Control", "no-cache");
    xhrConfig.onload = resolve;
    xhrConfig.onerror = reject; // () => reject(xhrConfig.statusText); //  console.error(xhrConfig.statusText);
    xhrConfig.send(null);
  });

function onConfigResult(config) {
  // set base porperties
  setBaseUrls({
    baseUrl: config.baseUrlForApi,
    staticBaseUrl: config.staticBaseUrl,
    authorizationUrl: config.authorizationUrl,
    lbUrl: config.baseLbApiUrl,
    server: config.server,
  });
  setConsole(config.server);
  activateGoogleTagManager(config.server, config.googleTag);
  //Oidc configuration
  setUserManagerAuthorization(config.authorizationUrl, config.clientId);
}

function requestOnLoad() {
  if (xhrConfig.readyState === 4 && xhrConfig.status === 200) {
    let serverConfig = JSON.parse(xhrConfig.responseText);
    onConfigResult(serverConfig);
    const { store, persist } = ConfigStore();
    setI18next();
    ReactDOM.render(
      <React.StrictMode>
        <React.Suspense fallback={LbLoader}>
          <I18nextProvider i18n={i18n}>
            <Router history={history}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persist}>
                  <App />
                </PersistGate>
              </Provider>
            </Router>
          </I18nextProvider>
        </React.Suspense>
      </React.StrictMode>,
      document.getElementById("root")
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // serviceWorker.register();
  }
}

fetchConfig().then(requestOnLoad).catch();
