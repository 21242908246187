import * as Oidc from 'oidc-client';

export const userManagerConfig = {
  clockSkew: 60 * 60 * 24 * 365,
  //client_id: clientId,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/auth/callback`,
  response_type: 'code',
  scope: 'onboarding_api_global',
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/`,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/silent_renew`,
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTime: 60,
  inactiveTimeSeconds: 250,
  //   authority: 'https://accounts.myliberty.02.test.lb.ge',
  //   client_id: 'internet_bank',
};

export let mrg = null;

export const setUserManagerAuthorization = (authority, client_id) => {
  mrg = new Oidc.UserManager({
    ...userManagerConfig,
    authority,
    client_id,
  });
};

export const OidcLoginRedirect = () => {
  mrg.signinRedirect();
};

export const OidcGetUser = async () => {
  try {
    const user = await new Oidc.UserManager({ response_mode: 'query' }).signinRedirectCallback();
    return user;
  } catch (error) {
    console.log('user', error);
  }
};
