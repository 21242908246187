import {
  SET_KYC_ACTIVITY,
  SET_KYC_CONTACT,
  SET_KYC_SERVICES,
  SET_STATE_TO_INITIAL,
  SET_REGISTER_INITIAL,
  SET_APPLICATION,
} from "../const/application.const";

const initialState = () => ({
  initial: {
    idNumber: "",
    personalNumber: "",
    mobilePhone: "",
    phonePrefix: "",
    isDualCitizen: false,
    citizenshipCountryCode: "",
    //optional
    isEntrepreneur: false,
    companyName: "",
    companyNameEng: "",
    representatorFirstName: "",
    representatorFirstNameEng: "",
    representatorLastName: "",
    representatorLastNameEng: "",
    //
    legalFormId: null,
    individualEntrepreneur: false,
  },
  kycContact: {
    physicalAddress: "",
    legalAddress: "",
    companyEmail: "",
    representatorEmail: "",
    companyPhone: "",
    companyWebsite: "",
    companyNameEng: "",
    //optional
    isSameAddress: false,
  },
  kycActivity: {
    activityId: null,
    clientTypeId: null,
    additionalActivityId: null,
    countryId: null,
    employcountId: null,
    annualTurnoverId: null,
    expectedannualTurnoverId: null,
    clientRegistrationReasonId: null,
    additionalReason: "",
    anotherBankName: "",
    anotherBankCodes: [],
    hasBankAccount: undefined,
  },
  kycServices: {
    accountCurrencies: ["GEL"],
    createCard: undefined,
    CompanyNameOnCard: "",
    cardCurrencies: [],
    serviceCenterid: null,
    receiveEcommerce: false,
    receivePosterminal: false,
    receiveCashBack: false,
    brandName: "",
    ecommerceWebsite: "",
    //others
    delivery: "serviceCenter", // post
  },
});

export default function applicationReducer(state = initialState(), action) {
  switch (action.type) {
    case SET_REGISTER_INITIAL: {
      state = {
        ...state,
        initial: {
          ...state.initial,
          ...action.payload,
        },
      };
      break;
    }
    case SET_KYC_CONTACT: {
      state = {
        ...state,
        kycContact: {
          ...state.kycContact,
          ...action.payload,
        },
      };
      break;
    }
    case SET_KYC_ACTIVITY: {
      state = {
        ...state,
        kycActivity: {
          ...state.kycActivity,
          ...action.payload,
        },
      };
      break;
    }
    case SET_KYC_SERVICES: {
      state = {
        ...state,
        kycServices: {
          ...state.kycServices,
          ...action.payload,
        },
      };
      break;
    }
    case SET_APPLICATION: {
      state = {
        ...state,
        initial: {
          ...state.initial,
          idNumber: action.payload.idNumber || "",
          personalNumber: action.payload.personalNumber || "",
          mobilePhone: action.payload.mobilePhone || "",
          isEntrepreneur: action.payload.isEntrepreneur || false,
          companyName: action.payload.companyName || "",
          companyNameEng: action.payload.companyNameEng || "",
          representatorFirstName: action.payload.representatorFirstName || "",
          representatorFirstNameEng:
            action.payload.representatorFirstNameEng || "",
          representatorLastName: action.payload.representatorLastName || "",
          representatorLastNameEng:
            action.payload.representatorLastNameEng || "",
          //
          legalFormId: action.payload.legalFormId || null,
        },
        kycContact: {
          ...state.kycContact,
          physicalAddress: action.payload.kycContact.physicalAddress || "",
          legalAddress: action.payload.kycContact.legalAddress || "",
          companyEmail: action.payload.kycContact.companyEmail || "",
          representatorEmail:
            action.payload.kycContact.representatorEmail || "",
          companyPhone: action.payload.kycContact.companyPhone || "",
          companyWebsite: action.payload.kycContact.companyWebsite || "",
          companyNameEng: action.payload.kycContact.companyNameEng || "",
          //optional
          isSameAddress: false,
        },
        kycActivity: {
          ...state.kycActivity,
          activityId: action.payload.kycActivity.activityId || null,
          clientTypeId: action.payload.kycActivity.clientTypeId || null,
          additionalActivityId:
            action.payload.kycActivity.additionalActivityId || null,
          countryId: action.payload.kycActivity.countryId || null,
          employcountId: action.payload.kycActivity.employcountId || null,
          annualTurnoverId: action.payload.kycActivity.annualTurnoverId || null,
          expectedannualTurnoverId:
            action.payload.kycActivity.expectedannualTurnoverId || null,
          clientRegistrationReasonId:
            action.payload.kycActivity.clientRegistrationReasonId || null,
          additionalReason: "",
          anotherBankName: "",
          anotherBankCodes: action.payload.kycActivity.anotherBankCodes || [],
          hasBankAccount: undefined,
        },
        kycServices: {
          ...state.kycServices,
          accountCurrencies: action.payload.kycServices.accountCurrencies || [
            "GEL",
          ],
          createCard: action.payload.kycServices.createCard || undefined,
          CompanyNameOnCard: action.payload.kycServices.companynameoncard || "",
          cardCurrencies: action.payload.kycServices.cardCurrencies || [],
          serviceCenterid: action.payload.kycServices.serviceCenterId || null,
          receiveEcommerce:
            action.payload.kycServices.receiveEcommerce || false,
          receivePosterminal:
            action.payload.kycServices.receivePosTerminal || false,
          receiveCashBack: action.payload.kycServices.receiveCashBack || false,
          brandName: action.payload.kycServices.brandName || "",
          ecommerceWebsite: action.payload.kycServices.ecommerceWebsite || "",
          //others
          delivery: "serviceCenter", // post
        },
      };
      break;
    }
    case SET_STATE_TO_INITIAL: {
      state = initialState();
      break;
    }
    default:
      return state;
  }
  return state;
}
