import { takeEvery, call, all, put } from 'redux-saga/effects';
import { getPhonePrefixes } from '../../../api/liberty.api';
import { setPhonePrefixes, setPhonePrefixesLoader } from '../actions/helper.actions';
import { START_FETCH_PHONE_PREFIXES } from '../const/helper.const';

// actions

//api

function* startFetchPhonePrefixesAsync(actions) {
  try {
    yield put(setPhonePrefixesLoader(true));
    let data = yield getPhonePrefixes();
    yield put(setPhonePrefixes(data));
  } catch (error) {
    // yield put(tesTestLoader(false));
  }
}

function* onStartFetchPhonePrefixes() {
  yield takeEvery(START_FETCH_PHONE_PREFIXES, startFetchPhonePrefixesAsync);
}

export default function* helperSaga() {
  yield all([call(onStartFetchPhonePrefixes)]);
}
