export let baseUrl;
export let staticBaseUrl;
export let authorizationUrl;
export let server;
export let lbUrl;

export let setBaseUrls = ({
  baseUrl: _baseUrl,
  staticBaseUrl: _staticContentBaseUrl,
  authorizationUrl: _authorizationUrl,
  server: _server,
  lbUrl: _lbUrl,
}) => {
  baseUrl = _baseUrl;
  staticBaseUrl = _staticContentBaseUrl;
  authorizationUrl = _authorizationUrl;
  server = _server;
  lbUrl = _lbUrl;
};



export const getUrlParams = url => `${url}?`.split('?')[1]
  .split('&').reduce((params, pair) =>
    ((key, val) => key ? {...params, [key]: val} : params)
    (...`${pair}=`.split('=').map(decodeURIComponent)), {});