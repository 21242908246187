import { SET_REFRESH_TOKEN, SET_USER_AVAILABLE_AUTHENTICATIONS, SET_USER_CREDENTIALS } from '../const/user.const';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  sessionId: null, 
  accessToken: null, 
  refreshToken: null, 
  availableAuthentications: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_CREDENTIALS: {
      state = { ...state, ...action.payload, isAuthenticated: true, isLoading: false };
      break;
    }
    case SET_REFRESH_TOKEN:
    case SET_USER_AVAILABLE_AUTHENTICATIONS: {
      state = { ...state, ...action.payload };
      break;
    }
    default:
      return state;
  }
  return state;
}
