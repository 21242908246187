import {
  SET_USER_CREDENTIALS,
  START_SET_USER_CREDENTIALS,
  SET_USER_AVAILABLE_AUTHENTICATIONS,
  START_CLEAR_USER_CREDENTIALS,
  START_CANCEL_USER_APPLICATION,
  START_FETCH_REFRESH_TOKEN,
  SET_REFRESH_TOKEN,
} from '../const/user.const';

export const startSetUserCredentials = (payload) => ({
  type: START_SET_USER_CREDENTIALS,
  payload,
});

export const setUserCredentials = ({ sessionId, accessToken , refreshToken ,  isAuthenticated }) => ({
  type: SET_USER_CREDENTIALS,
  payload: { sessionId, accessToken , refreshToken ,  isAuthenticated },
});

export const setUserAvailableAuthentications = ({ availableAuthentications }) => ({
  type: SET_USER_AVAILABLE_AUTHENTICATIONS,
  payload: { availableAuthentications },
});

export const startClearUserCredentials = () => ({
  type: START_CLEAR_USER_CREDENTIALS,
});
export const startCancelUserApplication = () => ({
  type: START_CANCEL_USER_APPLICATION,
});

export const startFetchRefreshToken = () => ({
  type: START_FETCH_REFRESH_TOKEN,
});
export const setRefreshToken = (payload) => ({
  type: SET_REFRESH_TOKEN,
  payload
});
