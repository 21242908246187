import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './reducers';

//saga
import RootSaga from './saga';

const persistConfig = {
  key: 'lb_digital_on_boarding',
  storage,
  whiteList: [],
  blacklist: [],
};

export let store;

const configureStore = () => {
  let persist;
  const sagaMiddleware = createSagaMiddleware();
  let middlewares = [sagaMiddleware];
  const persistedReducer = persistReducer(persistConfig, RootReducer);

  if (process.env.NODE_ENV === 'development') {
    store = createStore(
      persistedReducer,
      compose(
        applyMiddleware(...middlewares),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
  } else {
    store = createStore(persistedReducer, compose(applyMiddleware(...middlewares)));
  }
  persist = persistStore(store);

  sagaMiddleware.run(RootSaga);

  return { store, persist };
};

export default configureStore;
