import i18n from '../i18n';
import { resetApplicationState } from '../package/store/actions/application.action';
import { startClearUserCredentials } from '../package/store/actions/user.actions';
import { store } from '../package/store/index';
import { history } from './history';

const handleResponse = (response) => {
  if (response.status === 401) {
    // unauthorized
    store.dispatch(startClearUserCredentials());
    store.dispatch(resetApplicationState());
    history.push({
      pathname: '/',
    });
  }

  return new Promise((resolve, reject) => {
    if (response.ok) {
      if (response.status === 200) {
        var contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          response.json().then((json) => {
            resolve(json);
          });
        } else {
          resolve(response);
        }
      } else if (response.status === 204) {
        resolve(null);
      }
    } else {
      response.text().then((error) => {
        try {
          error = JSON.parse(error);
        } catch (err) {
          if (response.status === 403)
            error = {
              code: 'UNAUTHORIZED_ERROR',
              errors: [i18n.t('Common.UnauthorizedError')],
            };
          else error = { code: 'UNHANDLED_ERROR', errors: ['Unhandled Error'] };
        }
        reject(error);
      });
    }
  });
};

const handleError = (error) => {
  let err = error;
  try {
    err = JSON.parse(err);
  } catch (e) {
    err = { code: 'UNHANDLED_ERROR', errors: ['Unhandled Error'] };
  }

  return Promise.reject(err);
};

export const jsonRequest = (url, isPrivate = true, body, method = 'GET', optionalHeaders = {}) => {
  const headers = new Headers(optionalHeaders);

  headers.append('Accept', 'application/json');
  headers.append('Accept-Language', i18n.language);

  if (isPrivate === true) {
    let token = localStorage.getItem('lb_on_boarding_token');
    // body['sessionId'] = token;
    if (token && !headers.has('Authorization')) headers.append('Authorization', `Bearer ${token}`);
  }
  var options = { headers, mode: 'cors', method: method };

  if (body) {
    options.method = method;
    options.body = JSON.stringify(body);
    headers.append('Content-Type', 'application/json');
  }

  return fetch(url, options).then(
    (response) => {
      return handleResponse(response);
    },
    (error) => {
      return handleError(error, url);
    }
  );
};
